import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { tableService as service, roomService } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    tableId: '',
    roomId: '',
    tableName: '',
    numGuests: 0,
    canBeBooked: true,
    shape: null,
    location: {
        first: 0,
        second: 0,
    },
    orientation: 0,
    metadata: [],
    active: true,
}

export default function FormTableRoom() {
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? id : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'roomId',
            service: roomService.searchAll,
            keyopt: 'roomId',
            nameopt: 'roomName',
            col: 6,
        },
        {
            type: 'input',
            name: 'tableName',
            col: 6,
        },
        {
            type: 'input',
            name: 'numGuests',
            inputtype: 'number',
            step: 0.01,
            col: 3,
        },
        {
            type: 'checkbox',
            name: 'canBeBooked',
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'shape',
            options: [
                {
                    key: 'ROUND',
                    name: t('table.shape.ROUND'),
                },
                {
                    key: 'RECTANGULAR',
                    name: t('table.shape.RECTANGULAR'),
                },
            ],
            col: 6,
        },
        {
            type: 'input',
            name: 'location.first',
            col: 6,
        },
        {
            type: 'input',
            name: 'location.second',
            col: 6,
        },
        {
            type: 'input',
            name: 'orientation',
            inputtype: 'number',
            step: 0.01,
            col: 3,
        },
        {
            type: 'checkbox',
            name: 'active',
            col: 3,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['roomId']) {
            return t('table.error.roomId')
        }
        if (!object['tableName']) {
            return t('table.error.tableName')
        }
        if (!object['numGuests'] || object['numGuests'] < 1) {
            return t('table.error.numGuests')
        }
        if (!object['shape'] || object['shape'] < 1) {
            return t('table.error.shape')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'table'}
                urledit={'/table/edit/'}
                urlCancel={'/table'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
                id={idRow}
                fieldId={'tableId'}
            />
        </>
    )
}
