import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButtonMui from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';


const ListItemButton = styled(ListItemButtonMui)(({ theme }) => ({
  transition: "0.15s padding ease",
  "&.secondary":{
      paddingLeft: "2em",
      fontSize: "0.8em",
      ".MuiListItemText-root": {
        "span":{
          fontSize: "0.95em",
        },
      },
      ".MuiSvgIcon-root":{
        width: "0.85em"
      }
  },
  ".MuiListItemText-root": {
    opacity:"1",
    paddingLeft: "15px",
    "span":{
      transition: "font 0.3s ease",
      opacity:"1"
    },
  },
  ".arrowmenu": {
    transition: "visibility 0s, opacity 0.3s linear",
    visibility:"hidden",
    opacity:"0"
  },
  "&:hover":{
    paddingLeft:"2em",
    ".MuiListItemText-root": {
      "span":{
        fontSize: "0.9em"
      },
    },
    ".arrowmenu": {
      visibility:"visible",
      opacity:"1"
    },
    
  },
  "&.secondary:hover":{
    paddingLeft:"2em",
    ".MuiListItemText-root": {
      "span":{
        fontSize: "0.8em"
      },
    },
    
  }
}));

export default function MenuElement(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(props.open);
  const t = props.t;

  React.useEffect(() => {
    setOpen(props.open)
 }, [props.open]);

  return (
       <>
        <List>
            <Link to={props.url} style={{ color:'inherit',textDecoration: 'none' }}>
                <ListItemButton
                className={props.inside?"secondary":"primary"}
                key={props.text}
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                }} >
                    <ListItemIcon
                        sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        }}
                    >
                        {props.icon}
                    </ListItemIcon>
                    <ListItemText primary={props.text} sx={{ opacity: open ? 1 : 0 }} />
                    <ListItemIcon className="arrowmenu"
                        sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        }}>
                        <ArrowForwardIcon />
                    </ListItemIcon>
                    
                </ListItemButton>
            </Link>
        </List>
        {!props.hiddenDivider && <Divider />}
        </>
  );
}