import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CrudEmpty from '../../crud/CrudEmpty'

export default function NotDev() {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState(null)

    return (
        <CrudEmpty i18n={'dashboard'} goback={true}>
            <div>
                <Grid container>
                    <Grid xs={12} style={{ textAlign: 'center', color: 'red' }}>
                        Aun no esta implentado
                    </Grid>
                </Grid>
            </div>
        </CrudEmpty>
    )
}
