import BaseService from './_base.service';

const URL = "/venue/vManager/1.0/rooms";

export const roomService = {
    searchAll,
    one,
    create,
    edit,
    removeLogic,
    searchAllName

};

function searchAll(callback) {
    return BaseService.get(URL, callback)
}

function searchAllName(roomName, callback) {
    return BaseService.get(URL+'/byName/'+encodeURI(roomName), callback)
}



function one(id,callback) {
    return BaseService.get(URL + "/" +id, callback);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}

function removeLogic(id,callback) {
    return BaseService.delete(URL + "/" +id, callback);
}