import CrudTable from '../../crud/CrudTable'
import { tableService as service, roomService } from '../../../services'
import { useTranslation } from 'react-i18next'

const initFilter = {
    roomId: '',
}

export default function Table() {
    const { t, i18n } = useTranslation()

    const search = function (
        page,
        rowsPerPage,
        filter,
        expendedFilter,
        callback
    ) {
        if (filter['roomId'] && filter['roomId'] != '') {
            roomService.one(filter['roomId'], (data, error) => {
                if (data && data['tables']) {
                    callback(data['tables'], false)
                } else {
                    callback([], false)
                }
            })
        } else {
            callback([], false)
        }
    }

    const createEstructureFilter = [
        {
            type: 'autocomplete',
            name: 'roomId',
            service: roomService.searchAll,
            keyopt: 'roomId',
            nameopt: 'roomName',
            col: 4,
        },
    ]
    const paintCell = {
        shape: (value, object) => {
            if (value) {
                if (value == 'ROUND') {
                    return <>{t('table.shape.ROUND')}</>
                } else if (value == 'RECTANGULAR') {
                    return <>{t('table.shape.RECTANGULAR')}</>
                } else {
                    return <>{''}</>
                }
            } else {
                return <>{''}</>
            }
        },
    }

    return (
        <CrudTable
            service={service}
            urledit={'/table/edit/'}
            urlnew={'/table/new/'}
            i18n={'table'}
            fieldstable={[
                'tableName',
                'numGuests',
                'canBeBooked',
                'shape',
                'active',
            ]}
            search={search}
            hiddenpagination={true}
            expendedFilter={true}
            notexport={true}
            fieldId={'roomId'}
            filter={initFilter}
            estructureForm={createEstructureFilter}
            paintCell={paintCell}
        />
    )
}
