import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { isNotLoginLogin } from '../../utils/session'

const LoginRoute = ({ children }) => {
    const isLoggedNotIn = isNotLoginLogin()
    if (!isLoggedNotIn) {
        return children
    } else {
        return <Navigate to={{ pathname: '/' }} />
    }
}

export default LoginRoute
