import React, { useState, useEffect }  from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CustomPaginationActionsTable from "../table/Table"
import Filter from "../filter/Filter"
import { Link } from 'react-router-dom';
import Layout from '../layout/Layout';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";



export default function CrudEmpty(props) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();


  return (
    <Layout t={t} title={t(props.i18n+".title")} goback={props.goback?true:false}>
            <DivPanel>
              {props.children}
            </DivPanel>
    </Layout>
  );

}

const DivPanel =  styled.div`
    padding: 2em;
    @media (max-width: 767px) {
      padding: 0.5em;
    }
`;

const DivTable =  styled.div`
`;
