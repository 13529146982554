import BaseService from './_base.service';

const URL = "/venue/aManager/1.0/venues";

export const veneuService = {
    searchAll,
    one,
    create,
    edit,
    removeLogic

};

function searchAll(callback) {
    return BaseService.get(URL, callback)
}


function one(id,callback) {
    return BaseService.get(URL + "/" +id, callback);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}

function removeLogic(id,callback) {
    return BaseService.delete(URL + "/" +id, callback);
}