import BaseService from './_base.service';

const URL = "/venue/vManager/1.0/rooms/";

export const tableService = {
    searchAll,
    one,
    create,
    edit,
    removeLogic

};

function searchAll(roomId,callback) {
    return BaseService.get(URL+roomId+"/tables", callback)
}

function one(id,callback) {
    return BaseService.get(URL + "/" +id, callback);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL+'/'+bodyelement['roomId']+'/tables', body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}

function removeLogic(id,callback) {
    return BaseService.delete(URL + "/" +id, callback);
}