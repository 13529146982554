import CrudTable from '../../crud/CrudTable'
import { roomService as service } from '../../../services'

const initFilter = {
    roomName: '',
}

export default function Room() {
    const search = function (
        page,
        rowsPerPage,
        filter,
        expendedFilter,
        callback
    ) {
        if (filter['roomName'] && filter['roomName'] != '') {
            service.searchAllName(filter['roomName'], callback)
        } else {
            service.searchAll(callback)
        }
    }

    const createEstructureFilter = [
        {
            type: 'input',
            name: 'roomName',
            col: 4,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/room/edit/'}
            urlnew={'/room/new/'}
            i18n={'room'}
            fieldstable={[
                'roomName',
                'maxCapacity',
                'priority',
                'canBeBooked',
                'venueName',
            ]}
            search={search}
            hiddenpagination={true}
            notexport={true}
            fieldId={'roomId'}
            filter={initFilter}
            estructureForm={createEstructureFilter}
        />
    )
}
