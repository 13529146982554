import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import DeleteIcon from '@mui/icons-material/Delete'
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { Box } from '@mui/system'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

let comboloadsformtable: any[] = []

export default function FormTable(props) {
    const initoptions = () => {
        var valuesfields = Array()
        for (let field of props.fields) {
            if (field.type == 'autocomplete' && !field.options) {
                var fn = field.name
                console.log(fn)
                valuesfields[fn] = Array()
                //valuesfields[fn].push({key: -1, name:"Selecciona una opción"})
            } else if (field.type == 'autocomplete' && field.options) {
                var fn = field.name
                valuesfields[fn] = field.options
            }
        }
        return valuesfields
    }

    const initsetValueAux = () => {
        var valuesfields = Array()
        for (let field of props.fields) {
            if (field.setvalue) {
                valuesfields[field.name] = field.setvalue
            }
        }
        return valuesfields
    }

    const inithandlers = () => {
        var valuesfields = Array()
        for (let field of props.fields) {
            if (field.type == 'autocomplete' && field.handler) {
                valuesfields[field.name] = field.handler
            }
        }
        return valuesfields
    }

    const initservicerelation = () => {
        var valuesfields = Array()
        for (let field of props.fields) {
            if (field.type == 'autocomplete' && field.servicerelation) {
                valuesfields[field.name] = field.servicerelation
            }
        }
        return valuesfields
    }

    const initrelationfield = () => {
        var valuesfields = Array()
        for (let field of props.fields) {
            if (field.type == 'autocomplete' && field.relationfield) {
                valuesfields[field.name] = field.relationfield
            }
        }
        return valuesfields
    }

    const [
        {
            page,
            idrow,
            deleteaction,
            iconDeleteAction,
            colorIconDeleteAction,
            actionQuestion,
            open,
            rows,
            onlyread,
            parent,
            options,
            valueform,
            setValueAux,
            handlers,
            servicerelation,
            relationfield,
            idAux,
        },
        setState,
    ] = useState({
        page: props.page,
        idrow: '',
        deleteaction: props.deleteaction ? props.deleteaction : true,
        iconDeleteAction: props.iconDeleteAction ? (
            props.iconDeleteAction
        ) : (
            <DeleteIcon />
        ),
        colorIconDeleteAction: props.colorIconDeleteAction
            ? props.colorIconDeleteAction
            : 'red',
        actionQuestion: props.actionQuestion
            ? props.actionQuestion
            : '¿Estas seguro que deseas borrarlo?',
        open: false,
        rows: props.rows ? props.rows : [],
        onlyread: props.onlyread ? props.onlyread : false,
        parent: props.parent ? props.parent : [],
        options: initoptions(),
        valueform: Array(),
        setValueAux: initsetValueAux(),
        handlers: inithandlers(),
        servicerelation: initservicerelation(),
        relationfield: initrelationfield(),
        idAux: -2,
    })

    const initrow = props.initrow
    const fields = props.fields
    const headers = props.headers
    const fieldId = props.fieldId
    const fieldName = props.fieldName
    const updateRows = props.updateRows

    const initValues = function (rows) {
        var valuesfields = Array()
        if (rows && rows.length > 0) {
            for (let row of rows) {
                var valuesRow = Array()
                for (let field of props.fields) {
                    if (field.type == 'button') {
                    } else if (field.type == 'autocomplete') {
                        if (field.multiple) {
                            var result = Array()
                            var array = getValueField(row, field.name)

                            for (let index = 0; index < array.length; index++) {
                                const element = array[index]
                                if (element.id && element.id > 0) {
                                    result.push({
                                        key: element.id,
                                        name: element.name,
                                    })
                                }
                            }
                            valuesRow[field.name] = result
                        } else {
                            valuesRow[field.name] = getValueField(
                                row,
                                field.name
                            )
                        }
                    } else {
                        if (field.inputtype && field.inputtype == 'number') {
                            let v = getValueField(row, field.name)
                            if (v) {
                                try {
                                    console.log('No puedo convertir este ' + v)
                                    valuesRow[field.name] = v.toFixed(2)
                                } catch (e: any) {
                                    console.log('No puedo convertir este ' + v)
                                    valuesRow[field.name] = getValueField(
                                        row,
                                        field.name
                                    )
                                }
                            } else {
                                valuesRow[field.name] = 0
                            }
                        } else {
                            valuesRow[field.name] = getValueField(
                                row,
                                field.name
                            )
                        }
                    }
                }

                valuesfields[row[fieldId]] = valuesRow
            }
        }
        setState((currentstate) => ({
            ...currentstate,
            ['valueform']: valuesfields,
        }))
    }

    const handlerDel = function (id) {
        var update = {
            idrow: id,
            open: true,
        }
        setState((currentstate) => ({ ...currentstate, ...update }))
    }

    const handlerDelConfirm = function () {
        console.log('DELETE ->' + idrow)
        var pos = -1
        var count = 0
        for (let row of rows) {
            if (row[fieldId] == idrow) {
                pos = count
            }
            count = count + 1
        }

        if (pos >= 0) {
            rows.splice(pos, 1)
            var update = {
                rows: rows,
                open: false,
            }
            setState((currentstate) => ({ ...currentstate, ...update }))

            updateRows(fieldName, rows)
        } else {
            setState((currentstate) => ({ ...currentstate, ['open']: false }))
        }
    }

    const deleteactionButton = function (row) {
        return (
            <Button
                variant="contained"
                color="error"
                style={{
                    backgroundColor: colorIconDeleteAction,
                    color: 'white',
                }}
                onClick={() => handlerDel(row[fieldId])}
                tabIndex={-1}
            >
                {' '}
                {iconDeleteAction}{' '}
            </Button>
        )
    }

    const handleClose = () => {
        setState((currentstate) => ({ ...currentstate, ['open']: false }))
    }

    const getValueField = (object, field) => {
        if (object) {
            if (field.includes('.')) {
                const parts = field.split('.')
                var objfield = object[parts[0]]
                console.log(objfield)
                if (Array.isArray(objfield)) {
                    var result = Array()
                    for (let entry of objfield) {
                        result.push(
                            getValueField(
                                entry,
                                field.substring(parts[0].length + 1)
                            )
                        )
                    }
                    return result
                } else {
                    return getValueField(
                        objfield,
                        field.substring(parts[0].length + 1)
                    )
                }
            } else {
                return object[field]
            }
        } else {
            return ''
        }
    }

    const getRow = (id, name, value, aux = null) => {
        var rowsNew = null
        var index = 0

        for (let row of rows) {
            if (row[fieldId] == id) {
                if (setValueAux[name]) {
                    row = setValueAux[name](
                        setValueField,
                        row,
                        value,
                        valueform,
                        setState,
                        id,
                        aux,
                        props.parent
                    )
                }
                rowsNew = setValueField(row, name, value)
                break
            }

            index = index + 1
        }

        if (rowsNew != null) {
            rows[index] = rowsNew
        }

        return rows
    }

    const getRowCombo = (id, name, value, valueform, aux = null) => {
        var rowsNew = null
        var index = 0

        for (let row of rows) {
            if (row[fieldId] == id) {
                if (setValueAux[name]) {
                    rowsNew = setValueAux[name](
                        setValueField,
                        row,
                        value,
                        valueform,
                        setState,
                        id,
                        aux,
                        props.parent
                    )
                } else {
                    rowsNew = setValueFieldCombo(row, name, aux ? aux : value)
                }
                break
            }

            index = index + 1
        }

        if (rowsNew != null) {
            rows[index] = rowsNew
        }

        return rows
    }

    const setValueField = (object, field, value) => {
        if (object) {
            if (field.includes('.')) {
                const parts = field.split('.')
                var objfield = object[parts[0]]
                object[parts[0]] = setValueField(
                    objfield,
                    field.substring(parts[0].length + 1),
                    value
                )
                return object
            } else {
                object[field] = value
                return object
            }
        } else {
            return object
        }
    }

    const setValueFieldCombo = (object, field, value) => {
        if (object) {
            if (field.includes('.')) {
                const parts = field.split('.')
                var objfield = object[parts[0]]
                object[parts[0]] = setValueField(
                    objfield,
                    field.substring(parts[0].length + 1),
                    value
                )
                return object
            } else {
                object[field] = { id: value.key, name: value.name }

                return object
            }
        } else {
            return object
        }
    }

    const handleChange = function (event) {
        let namecomplete = event.target.name
        let name = namecomplete.split('|')[0]
        let id = namecomplete.split('|')[1]
        let value = event.target.value
        var valuerFormAux = { ...valueform }
        var rv = valuerFormAux[id]
        rv[name] = value
        var rowsNew = getRow(id, name, value)

        var update = {
            valueform: valuerFormAux,
            rows: rowsNew,
        }
        setState((currentstate) => ({ ...currentstate, ...update }))

        updateRows(fieldName, rowsNew)
    }

    const handleChangeAutocomplete = function (name, value, id) {
        console.log('ID ES => ' + id)

        if (value && (value.key || value.key === 0)) {
            var valuerFormAux = { ...valueform }
            var rv = valuerFormAux[id]
            rv[name] = value

            var rowsNew = getRowCombo(id, name, value.key, valuerFormAux, value)

            var update = {
                valueform: valuerFormAux,
                rows: rowsNew,
            }
            setState((currentstate) => ({ ...currentstate, ...update }))

            updateRows(fieldName, rowsNew)

            if (handlers[name]) {
                handlers[name](value)
            }

            if (relationfield[name] && servicerelation[name]) {
                updateRelationCombo(
                    relationfield[name],
                    servicerelation[name],
                    value.key,
                    name
                )
            }
        } else if (Array.isArray(value) && value[0]) {
            const valuesSet = Array()
            for (let index = 0; index < value.length; index++) {
                const element = value[index]
                valuesSet.push({ id: element.key })
            }

            var valuerFormAux = { ...valueform }
            var rv = valuerFormAux[id]
            rv[name] = valuesSet
            var rowsNew = getRowCombo(id, name, valuerFormAux, value)

            var update = {
                valueform: valuerFormAux,
                rows: rowsNew,
            }
            setState((currentstate) => ({ ...currentstate, ...update }))
            updateRows(fieldName, rowsNew)
        } else {
            var valuerFormAux = { ...valueform }
            var rv = valuerFormAux[id]
            rv[name] = value
            var rowsNew = getRowCombo(id, name, valuerFormAux, value)
            var update = {
                valueform: valuerFormAux,
                rows: rowsNew,
            }
            setState((currentstate) => ({ ...currentstate, ...update }))
            updateRows(fieldName, rowsNew)

            if (handlers[name]) {
                handlers[name](1234566879702)
            }
        }
    }

    const updateRelationCombo = function (field, service, key, name) {}

    const getLabelOption = function (option) {
        if (option && option.name) {
            if (typeof option.name === 'string') {
                if (option.code) {
                    return option.code + ' - ' + option.name
                } else {
                    return option.name
                }
            } else {
                return ''
            }
        } else if (option && option.label && typeof option.label === 'string') {
            return option.label
        } else {
            return ''
        }
    }

    const renderTitle = function (field) {
        return (
            <label style={{ fontSize: '1.3em', fontWeight: 'bold' }}>
                {field.label}
            </label>
        )
    }

    const handleFocus = (event) => event.target.select()

    const renderInput = function (id, field) {
        return (
            <TextField
                id={field.name + '|' + id}
                style={{ width: '100%' }}
                name={field.name + '|' + id}
                label={''}
                value={valueform[id] ? valueform[id][field.name] : null}
                onChange={handleChange}
                type={field.inputtype ? field.inputtype : 'text'}
                onKeyDown={(evt) => createLineTab(field, evt, id)}
                inputProps={{
                    step: field.step ? field.step : '',
                    autoComplete: 'new-password',
                    readOnly: field.readonly || onlyread ? true : false,
                    tabindex: field.readonly || onlyread ? '-1' : '',
                }}
                InputLabelProps={{
                    shrink:
                        field.inputtype &&
                        (field.inputtype == 'date' ||
                            field.inputtype == 'number'),
                }}
                sx={{
                    padding: '0px',
                    '& div': {
                        '& .MuiOutlinedInput-input': {
                            padding: '6px !important',
                            fontSize: '0.8em',
                        },
                    },
                }}
                onFocus={(evt) => {
                    if (field.inputtype && field.inputtype == 'number')
                        evt.target.select()
                }}
            />
        )
    }

    const newHandler = (event, value) => {}

    const searchCode = (field, e, id) => {
        if (field.searchcode) {
            if (e.nativeEvent.key == 'Tab') {
                if (field.nextFocus) {
                    e.preventDefault()
                }
                var code = e.target.value
                var optionsfield = options[field.name]
                for (const key in optionsfield) {
                    if (
                        Object.prototype.hasOwnProperty.call(optionsfield, key)
                    ) {
                        const element = optionsfield[key]
                        if (element.code && element.code == code) {
                            /*if(element.nameComplete){
                element.name = element.nameComplete;
              }*/
                            handleChangeAutocomplete(field.name, element, id)
                            if (field.nextFocus) {
                                document
                                    .getElementById(field.nextFocus + '|' + id)
                                    ?.focus()
                                ;(
                                    document.getElementById(
                                        field.nextFocus + '|' + id
                                    ) as HTMLInputElement
                                ).select()
                            }
                            break
                        } else {
                            e.preventDefault()
                        }
                    }
                }
            }
        }
    }

    const renderAutocomplete = function (id, field) {
        if (field.multiple) {
            return (
                <Autocomplete
                    multiple
                    id={field.name}
                    readOnly={field.readonly || onlyread}
                    options={options[field.name]}
                    getOptionLabel={getLabelOption}
                    value={valueform[id] ? valueform[id][field.name] : null}
                    onChange={(event, value) =>
                        handleChangeAutocomplete(field.name, value, id)
                    }
                    sx={{
                        padding: '0px',
                        fontSize: '0.8em',
                        '& div': {
                            '& .MuiOutlinedInput-root': {
                                padding: '0px !important',
                                '& input': { fontSize: '0.8em' },
                            },
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={field.label}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                                readOnly:
                                    field.readonly || onlyread ? true : false,
                            }}
                            onKeyDown={(evt) => searchCode(field, evt, id)}
                            autoFocus={field.autoFocus ? true : false}
                            sx={{ fontSize: '0.8em !important' }}
                        />
                    )}
                />
            )
        } else {
            return (
                <Autocomplete
                    id={field.name}
                    readOnly={field.readonly || onlyread}
                    options={options[field.name]}
                    getOptionLabel={getLabelOption}
                    value={valueform[id] ? valueform[id][field.name] : null}
                    onChange={(event, value) =>
                        handleChangeAutocomplete(field.name, value, id)
                    }
                    sx={{
                        padding: '0px',
                        fontSize: '0.8em',
                        '& div': {
                            '& .MuiOutlinedInput-root': {
                                padding: '0px !important',
                                '& input': { fontSize: '0.8em' },
                            },
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={field.label}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                                readOnly:
                                    field.readonly || onlyread ? true : false,
                            }}
                            onKeyDown={(evt) => searchCode(field, evt, id)}
                            autoFocus={field.autoFocus ? true : false}
                            sx={{ fontSize: '0.8em !important' }}
                        />
                    )}
                />
            )
        }
    }

    const renderButton = function (id, field) {
        return (
            <Button
                variant="contained"
                style={{ backgroundColor: 'black', color: 'white' }}
                onClick={() => field.press(valueform[id])}
            >
                {' '}
                {field.icon}{' '}
            </Button>
        )
    }

    const renderField = function (row, field) {
        switch (field.type) {
            case 'input':
                return renderInput(row[fieldId], field)
            case 'autocomplete':
                return renderAutocomplete(row[fieldId], field)
            case 'button':
                return renderButton(row[fieldId], field)
            case 'title':
                return renderTitle(field)
            default:
                return renderInput(row[fieldId], field)
        }
    }

    const createLineTab = function (field, e, id) {
        if (field.tabeventnew) {
            if (e.nativeEvent.key == 'Tab') {
                var isLast = rows[rows.length - 1].id == id
                if (isLast) {
                    e.preventDefault()
                    clickAddLine(e)
                }
            }
        }
    }

    const clickAddLine = function (event) {
        setState((currentState) => ({ ...currentState, ['idAux']: idAux - 1 }))
    }

    useEffect(() => {
        var idLine = idAux + 0
        console.log('ID  ==> ' + idLine)
        var line = { ...initrow }
        line[fieldId] = idLine
        console.log('LINE  ==> ' + line)
        var rowsAux: any[] = []
        rowsAux.push(...rows)
        rowsAux.push(line)

        var valuerFormAux = { ...valueform }
        valuerFormAux[idLine] = line

        var update = {
            valueform: valuerFormAux,
            rows: rowsAux,
        }
        setState((currentstate) => ({ ...currentstate, ...update }))
    }, [idAux])

    const loadCombos = function (options) {
        for (let field of fields) {
            console.log(field)
            if (field.type == 'autocomplete' && field.service) {
                if (
                    options[field.name].length == 0 &&
                    !comboloadsformtable.includes(field.name)
                ) {
                    comboloadsformtable.push(field.name)

                    field.service((data, error) => {
                        if (data && data.data) {
                            var aux = { ...options }
                            aux[field.name] = data.data

                            setState((currentState) => ({
                                ...currentState,
                                ['options']: aux,
                            }))
                        }
                    })
                }
            }
        }
    }

    useEffect(() => {
        comboloadsformtable = []

        return () => {
            setState({
                page: 0,
                idrow: '',
                deleteaction: true,
                iconDeleteAction: <DeleteIcon />,
                colorIconDeleteAction: 'red',
                actionQuestion: '¿Estas seguro que deseas borrarlo?',
                open: false,
                rows: [],
                onlyread: false,
                parent: [],
                options: [],
                valueform: Array(),
                setValueAux: [],
                handlers: [],
                servicerelation: [],
                relationfield: [],
                idAux: -2,
            })
        }
    }, [])

    useEffect(() => {
        loadCombos(options)
    }, [options])

    useEffect(() => {
        setState((currentState) => ({ ...currentState, ['rows']: props.rows }))
        initValues(props.rows)
    }, [props.rows])

    useEffect(() => {
        setState((currentState) => ({
            ...currentState,
            ['onlyread']: props.onlyread,
        }))
    }, [props.onlyread])

    useEffect(() => {
        setState((currentState) => ({ ...currentState, ['rows']: props.rows }))
        initValues(props.rows)
    }, [props.forceupdatetable])

    const getColor = function (row) {
        if (props.conditionColor) {
            return props.conditionColor(row)
        } else {
            return 'black'
        }
    }

    return (
        <Grid container>
            {!onlyread && !props.removebuttonewline && (
                <Grid
                    item
                    sm={12}
                    style={{ textAlign: 'right', marginBottom: '10px' }}
                >
                    <Button
                        id="linesbutton"
                        variant="contained"
                        color="primary"
                        onClick={clickAddLine}
                    >
                        {' '}
                        {props.labelbtn ? props.labelbtn : 'Añadir linea'}{' '}
                    </Button>
                </Grid>
            )}
            <Grid
                item
                sm={12}
                style={{ textAlign: 'right', marginBottom: '10px' }}
            >
                <TableContainer
                    component={Paper}
                    sx={{ boxShadow: 'none !important' }}
                >
                    <Table aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                {headers.map((h) => (
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        width={h.colSpan ? h.colSpan : '10%'}
                                    >
                                        {h.label}
                                    </TableCell>
                                ))}
                                {!onlyread && deleteaction ? (
                                    <TableCell
                                        component="th"
                                        scope="row"
                                    ></TableCell>
                                ) : (
                                    ''
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows &&
                                rows.map((row) => (
                                    <TableRow key={row[fieldId]}>
                                        {fields.map((field) => (
                                            <TableCell
                                                component="td"
                                                scope="row"
                                                width={
                                                    field.colSpan
                                                        ? field.colSpan
                                                        : '10%'
                                                }
                                                sx={{
                                                    padding: '2px 3px',
                                                    '& input': {
                                                        color: getColor(row),
                                                    },
                                                }}
                                            >
                                                {renderField(row, field)}
                                            </TableCell>
                                        ))}
                                        {!onlyread &&
                                        deleteaction &&
                                        row &&
                                        row[fieldId] ? (
                                            <TableCell
                                                component="td"
                                                scope="row"
                                                variant="body"
                                                sx={{ padding: '2px 3px' }}
                                            >
                                                {deleteactionButton(row)}
                                            </TableCell>
                                        ) : (
                                            ''
                                        )}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box sx={style}>
                    <div style={{ marginBottom: '30px' }}>{actionQuestion}</div>
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ marginRight: '30px' }}
                            onClick={handleClose}
                        >
                            {' '}
                            No
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlerDelConfirm}
                        >
                            {' '}
                            Sí{' '}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </Grid>
    )
}
