import CrudTable from '../../crud/CrudTable'
import { veneuService as service } from '../../../services'

const initFilter = {
    name: null,
}

export default function Veneu() {
    return (
        <CrudTable
            service={service}
            urledit={'/veneu/edit/'}
            urlnew={'/veneu/new/'}
            filter={initFilter}
            i18n={'veneu'}
            fieldstable={[
                'venueVatId',
                'venueName',
                'venueAddress',
                'venueCity',
                'venuePhone',
                'venueEmail',
                'active',
            ]}
            hiddenFilter={true}
            hiddenpagination={true}
            notexport={true}
            fieldId={'venueId'}
        />
    )
}
