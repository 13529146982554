import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { roomService as service, veneuService } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    venueId: '',
    roomName: '',
    maxCapacity: 0,
    priority: 0,
    canBeBooked: true,
}

export default function FormRoom() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? id : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'venueId',
            service: veneuService.searchAll,
            keyopt: 'venueId',
            nameopt: 'venueName',
            col: 6,
        },
        {
            type: 'input',
            name: 'roomName',
            col: 6,
        },
        {
            type: 'input',
            name: 'maxCapacity',
            inputtype: 'number',
            step: 0.01,
            col: 3,
        },
        {
            type: 'input',
            name: 'priority',
            inputtype: 'number',
            step: 0.01,
            col: 3,
        },
        {
            type: 'checkbox',
            name: 'canBeBooked',
            col: 3,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['roomName']) {
            return t('room.error.roomName')
        }
        if (!object['maxCapacity'] || object['maxCapacity'] < 1) {
            return t('room.error.maxCapacity')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'room'}
                urledit={'/room/edit/'}
                urlCancel={'/room'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
                id={idRow}
                fieldId={'venueId'}
            />
        </>
    )
}
