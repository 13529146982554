import * as React from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MenuElement from './MenuItem'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import TableBarIcon from '@mui/icons-material/TableBar'
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu'
import ReceiptIcon from '@mui/icons-material/Receipt'
import StorefrontIcon from '@mui/icons-material/Storefront'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const closedMixinXs = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `0px`,
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
        '& .MuiListItemText-root': {
            display: 'none',
        },
        '& .MuiListItem-root': {
            paddingLeft: '8px !important',
        },
        '& .MuiListItemIcon-root': {
            minWidth: '26px',
        },
    }),
    [theme.breakpoints.down('sm')]: {
        ...(!open && {
            ...closedMixinXs(theme),
            '& .MuiDrawer-paper': closedMixinXs(theme),
            '& .MuiListItemText-root': {
                display: 'none',
            },
        }),
    },
}))

export default function Menu(props) {
    const theme = useTheme()
    const [open, setOpen] = React.useState(props.open)

    const t = props.t

    const closeAll = (id) => {}

    React.useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={props.handleDrawerClose}>
                    {theme.direction === 'rtl' ? (
                        <ChevronRightIcon />
                    ) : (
                        <ChevronLeftIcon />
                    )}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <>
                <MenuElement
                    url={'/dashboard'}
                    icon={<DashboardIcon />}
                    text={t('menu.dashboard')}
                />
                <MenuElement
                    url={'/veneu'}
                    icon={<StorefrontIcon />}
                    text={t('menu.restaurant')}
                />
                <MenuElement
                    url={'/room'}
                    icon={<MeetingRoomIcon />}
                    text={t('menu.rooms')}
                />
                <MenuElement
                    url={'/table'}
                    icon={<TableBarIcon />}
                    text={t('menu.table')}
                />
                <MenuElement
                    url={'/menu'}
                    icon={<RestaurantMenuIcon />}
                    text={t('menu.menu')}
                />
                <MenuElement
                    url={'/tickets'}
                    icon={<ReceiptIcon />}
                    text={t('menu.tickets')}
                />
            </>
        </Drawer>
    )
}
