import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { veneuService as service } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    venueName: '',
    venueVatId: '',
    venueAddress: '',
    venueCity: '',
    venueProvince: '',
    venueCountry: '',
    venuePostalCode: '',
    venueEmail: '',
    venuePhone: '',
    totalPeriodOperatingHours: 0,
    venueCost: 0,
    active: true,
}

export default function FormVeneu() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? id : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'venueName',
            col: 6,
        },
        {
            type: 'input',
            name: 'venueVatId',
            col: 6,
        },
        {
            type: 'input',
            name: 'venueAddress',
            col: 3,
        },
        {
            type: 'input',
            name: 'venueCity',
            col: 6,
        },
        {
            type: 'input',
            name: 'venueProvince',
            col: 3,
        },
        {
            type: 'input',
            name: 'venueCountry',
            col: 3,
        },
        {
            type: 'input',
            name: 'venuePostalCode',
            col: 3,
        },
        {
            type: 'input',
            name: 'venueEmail',
            col: 3,
        },
        {
            type: 'input',
            name: 'venuePhone',
            col: 3,
        },
        {
            type: 'input',
            name: 'totalPeriodOperatingHours',
            inputtype: 'number',
            step: 0.01,
            col: 3,
        },
        {
            type: 'input',
            name: 'venueCost',
            inputtype: 'number',
            step: 0.01,
            col: 3,
        },
        {
            type: 'checkbox',
            name: 'active',
            col: 3,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['venueName']) {
            return t('veneu.error.venueName')
        }
        if (!object['venueVatId']) {
            return t('veneu.error.venueVatId')
        }
        if (!object['venueAddress']) {
            return t('veneu.error.venueAddress')
        }
        if (!object['venueCity']) {
            return t('veneu.error.venueCity')
        }
        if (!object['venueProvince']) {
            return t('veneu.error.venueProvince')
        }
        if (!object['venueCountry']) {
            return t('veneu.error.venueCountry')
        }

        if (!object['venuePostalCode']) {
            return t('veneu.error.venuePostalCode')
        }
        if (!object['venuePhone']) {
            return t('veneu.error.venuePhone')
        }
        if (
            !object['totalPeriodOperatingHours'] ||
            object['totalPeriodOperatingHours'] < 1
        ) {
            return t('veneu.error.totalPeriodOperatingHours')
        }
        if (!object['venueCost'] || object['venueCost'] < 1) {
            return t('veneu.error.venueCost')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'veneu'}
                urledit={'/veneu/edit/'}
                urlCancel={'/veneu'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
                id={idRow}
                fieldId={'venueId'}
            />
        </>
    )
}
