import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import './language/i18n'
import { isNotLoginLogin } from './utils/session'
import LoginRoute from './components/route/LoginRoute'
import Login from './components/pages/login/Login'
import Dashboard from './components/pages/dasboard/Dashboard'
import PrivateRoute from './components/route/PrivateRoute'
import Veneu from './components/pages/veneu/Veneu'
import FormVeneu from './components/pages/veneu/FormVeneu'
import FormRoom from './components/pages/room/FormRoom'
import Room from './components/pages/room/Room'
import Table from './components/pages/table/Table'
import FormTableRoom from './components/pages/table/FormTableRoom'
import NotDev from './components/pages/dasboard/NotDev'

export default function App() {
    const isLoggedNotIn = isNotLoginLogin()

    return (
        <>
            <Router>
                <Routes>
                    <Route
                        path="/login"
                        element={
                            <LoginRoute>
                                <Login />
                            </LoginRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/veneu/new"
                        element={
                            <PrivateRoute>
                                <FormVeneu />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/veneu/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormVeneu />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/veneu"
                        element={
                            <PrivateRoute>
                                <Veneu />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/room/new"
                        element={
                            <PrivateRoute>
                                <FormRoom />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/room/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormRoom />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/room"
                        element={
                            <PrivateRoute>
                                <Room />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/table/new"
                        element={
                            <PrivateRoute>
                                <FormTableRoom />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/table/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormTableRoom />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/table"
                        element={
                            <PrivateRoute>
                                <Table />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/menu"
                        element={
                            <PrivateRoute>
                                <NotDev />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/tickets"
                        element={
                            <PrivateRoute>
                                <NotDev />
                            </PrivateRoute>
                        }
                    />

                    {isLoggedNotIn ? (
                        <Route path="/" element={<Login />} />
                    ) : (
                        <Route
                            path="/"
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        />
                    )}
                </Routes>
            </Router>
        </>
    )
}
